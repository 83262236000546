/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import AppContext from "../../store/app.context";
import {
	HiArrowPathRoundedSquare,
	HiBookmark,
	HiBuildingLibrary,
	HiCog6Tooth,
	HiCube,
	HiRectangleGroup,
	HiUser,
} from "react-icons/hi2";

function Sidenav({ color }) {
	const context = useContext(AppContext);
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");

	return (
		<>
			<div
				className="brand"
				style={{
					alignContent: "center",
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
				}}>
				<img className="brand-logo" src={logo} alt="Smartiva" />
				<span>Smartiva HQ</span>
			</div>
			<Menu
				theme="light"
				mode="inline"
				style={{
					marginTop: 32,
				}}>
				<Menu.Item key="dashboard" style={{ width: "100%" }}>
					<NavLink to="/master/dashboard">
						<span
							className="icon"
							style={{
								backgroundColor: page === "master/dashboard" ? color : "",
							}}>
							<HiRectangleGroup />
						</span>
						<span className="label">Dasbor</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item className="menu-item-header" key="application" style={{ width: "100%" }}>
					Aplikasi
				</Menu.Item>
				<Menu.Item key="modules" style={{ width: "100%" }}>
					<NavLink to="/master/modules">
						<span
							className="icon"
							style={{
								background: page === "master/modules" ? color : "",
							}}>
							<HiCube />
						</span>
						<span className="label">Modul</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key="users" style={{ width: "100%" }}>
					<NavLink to="/master/users">
						<span
							className="icon"
							style={{
								background: page === `/master/users` ? color : "",
							}}>
							<HiUser />
						</span>
						<span className="label">Pengguna</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key="schools" style={{ width: "100%" }}>
					<NavLink to="/master/schools">
						<span
							className="icon"
							style={{
								background: page === "master/schools" ? color : "",
							}}>
							<HiBuildingLibrary />
						</span>
						<span className="label">Sekolah</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key="transactions" style={{ width: "100%" }}>
					<NavLink to="/master/transactions">
						<span
							className="icon"
							style={{
								background: page === "master/transactions" ? color : "",
							}}>
							<HiArrowPathRoundedSquare />
						</span>
						<span className="label">Transaksi</span>
					</NavLink>
				</Menu.Item>

				<Menu.Item className="menu-item-header" key="other" style={{ width: "100%" }}>
					Lain-lain
				</Menu.Item>
				<Menu.Item key="subjects" style={{ width: "100%" }}>
					<NavLink to="/master/subjects">
						<span
							className="icon"
							style={{
								background: page === "master/subjects" ? color : "",
							}}>
							<HiBookmark />
						</span>
						<span className="label">Matpel</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key="settings" style={{ width: "100%" }}>
					<NavLink to="/master/settings">
						<span
							className="icon"
							style={{
								background: page === "master/settings" ? color : "",
							}}>
							<HiCog6Tooth />
						</span>
						<span className="label">Pengaturan</span>
					</NavLink>
				</Menu.Item>
			</Menu>
			<div className="aside-footer" style={{ paddingTop: 24 }}>
				<div
					className="footer-box"
					style={{
						background: color,
					}}>
					<span className="icon" style={{ color }}>
						<svg
							width={20}
							height={20}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
							/>
						</svg>
					</span>
					<h6>Ada pertanyaan?</h6>
					<p>Hubungi kami</p>
					<Button
						onClick={(e) => {
							e.preventDefault();
							window.location.href = "https://wa.me/6285155077455";
						}}
						type="primary"
						className="ant-btn-sm ant-btn-block">
						WhatsApp
					</Button>
				</div>
			</div>
		</>
	);
}

export default Sidenav;
